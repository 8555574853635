import { galleries } from "./constants";
import { getUpcoming } from "../../helpers";
import EventItem from "../components/EventItem";

export default function Galleries() {
  return (
    <div className="flex py-10">
      <div className="flex flex-wrap justify-center">
        {galleries.map((eventItem) => {
          const upcoming = getUpcoming(eventItem);

          const eventItemProps = { ...eventItem, upcoming };
          return <EventItem {...eventItemProps} />;
        })}
      </div>
    </div>
  );
}
