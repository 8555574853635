export const bio = [
  "<span class='font-bold'>Violet Moon</span> (they/them) is a polymathic artist who has performed across the United States as a selected dance artist, soloist, and touring company member. They are a City of Houston and Houston Arts Alliance Let Creativity Happen grant recipient and have had their paintings featured across the city. They write, sing, make music, craft, and value the process of Natalie Rogers’ model of Creative Connection.",
  "Recently, Violet has been exploring movement and artistry beyond conditioned Western structures. Their work is driven by a hope of dismantling indoctrination and the hierarchy of privilege to honor cultural and ancestral movement patterns. They are passionate about creating meaningful experiences that translate stories of pain, pleasure, and loss into something honest, resonant, and human.",
  "Their accomplishments include a Masters in Clinical Mental Health Counseling, somatic and expressive arts therapy training, guest dance artist contracts, international residencies, and a loyalty to their creative practice. Rooted in a deep reverence for the ever-evolving Self, Violet remains devoted to life’s dance."
];

export const accomplishments = [
    "City of Houston and Houston Arts Alliance 'Let Creativity Happen' Grant Recipient (2024, 2025)",
    "P.A.R.T.S Summer Residency - Thematic Lab with Stanley Ollivier on Energy & Care",
    "Individual Foundations of Expressive Arts Therapy 1 & 2 from Center For Creative Arts Therapy",
    "Masters of Arts in Clinical Mental Health Counseling from Palo Alto University, 4.0 GPA, 2024",
    "Counseling Intern with Chill Counseling, Supervised by Cori Hill, LPC LMFT",
    "Training Acceptance for Somatic Experiencing® International 2023",
    "Dance Source Houston Member, 2022-Present",
    "ARCOS Dance Company Guest Artist Contract 2016",
    "ARCOS Dance Intensive 2016",
    "Hubbard Street Dance Chicago Summer Intensive 2016",
    "Therapeutic Dance Movement Educator at The Monarch Institute, 2015",
    "Founding Member and Soloist for LÉON Contemporary Dance Company",
    "Hubbard Street Dance Chicago Winter Intensive 2015",
    "Hubbard Street Dance Chicago Summer Intensive 2015",
    "Apprentice with Revolve Dance Company",
  ];