import rehearsal from "../../assets/main2.jpg";
import mtg321 from "../../assets/shows/mtg32-1.jpg";
import mtg32 from "../../assets/shows/mtg32-2.jpg";
import { bio, accomplishments } from "./constants";

export default function Bio() {
  return (
    <div className="flex flex-col justify-center items-center overflow-no-scroll">
      <div className="flex max-w-[600px] flex-col lg:mt-10 relative">
        <div className="mb-4">
          <img
            src={rehearsal}
            className="w-full mb-[2px] h-auto"
            alt="violet standing with their arms on their hips looking at the distance in a dance studio with a wall clock in the background"
          />
          <div className="lg:text-right text-center text-[10px] text-black/40">
            {/* Stage 6 Rehearsal, 2015, Photo by Unknown */}
            Houston Botanic Garden, 2024, Photo by Hilary Schuhmacher
          </div>
        </div>
        <div className="text-xs text-justify p-4 lg:p-0">
          {bio.map((paragraph) => (
            <div
              className="first:mt-0 mt-4"
              dangerouslySetInnerHTML={{ __html: paragraph }}
            ></div>
          ))}
        </div>
        <div className="my-8">
          <img
            src={mtg32}
            className="w-full h-auto"
            alt="violet standing with their arms on their hips looking at the distance in a dance studio with a wall clock in the background"
          />
          <div className="lg:text-right text-center text-[10px] text-black/40">
            {/* Stage 6 Rehearsal, 2015, Photo by Unknown */}
            Mind The Gap XXXII, WATCHED: FOR YOUR VIEWING PLEASURE, 2024, Photo
            by Lynn Lane
          </div>
        </div>
        <div className="flex justify-around items-end flex-wrap text-[10px] overflow-scoll">
          {accomplishments.map((item) => (
            <div className="w-[180px] h-[60px] border-b-[1px] mb-4">{item}</div>
          ))}
        </div>
        <div className="my-8">
          <img
            src={mtg321}
            className="w-full h-auto"
            alt="violet standing with their arms on their hips looking at the distance in a dance studio with a wall clock in the background"
          />
          <div className="lg:text-right text-center text-[10px] text-black/40">
            {/* Stage 6 Rehearsal, 2015, Photo by Unknown */}
            Mind The Gap XXXII, WATCHED: FOR YOUR VIEWING PLEASURE, 2024, Photo
            by Lynn Lane
          </div>
        </div>
      </div>
    </div>
  );
}

// Limits not as blockers, but possibilities to redraw and honor the everchanging Self.
// is a creator
// and polymathic artist. Over the last decade, they have toured and
// performed select works as an invited artist, soloist, and company
// member across the United States. Violet writes, makes music, and has
// also had their visual artwork featured across Houston. In addition to
// recently choreographing several original works, Violet frequently
// studies around the world. They are often collaborating cross-medium
// and across venue while acknowledging/researching the privilege and
// requesting/respecting the consent of inquiry into unknown methods and
// experiences.
// In their creative practice, Violet is now exploring what movement
// means— defining artistry outside of and along the edges of the
// confines of standardized Western beauty. As a working mental health
// professional, Violet also likes to challenge the patterns and
// assumptions of engrained social behavior and beliefs of care. There
// are so many ways to relate to self and consequently others and life at
// large. This journey of dismantling indoctrination to welcome the
// natural rhythms of global and ancestral movement patterns to inform
// peformance as a metaphor and connector drives much of their work.
// Violet is passionate, focused, and hopeful about making processes and
// products that allow who they are to fill spaces authentically with the
// desire to translate pain, pleasure, and humanity into something
// impactful, resonsant, and meaningful for others.
