import clsx from "clsx";
import { useState } from "react";
import ExternalIcon from "../../../../assets/icons/External";

export default function EventItem(props) {
  const [hovered, setHover] = useState(false);

  return (
    <div
      key={props.event}
      className={parent + (props.upcoming ? "" : "bg-black/[3%]")}
    >
      {!props.upcoming && (
        <div
          className="w-1/3 h-auto"
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
        >
          {props.img ? (
            <img className={img} src={props.img} alt={props.event}/>
          ) : (
            <div className={img}>coming soon</div>
          )}
        </div>
      )}
      <div className="p-2 w-2/3 flex flex-col">
        <div className="flex w-full justify-between">
          <div className="font-bold text-xs">{props.event}</div>
          {props.eventLink && (
            <a
              href={props.eventLink}
              target="_blank"
              rel="noreferrer"
              className={externalLink}
            >
              <ExternalIcon />
            </a>
          )}
        </div>
        <div className="text-[10px]">{props.displayDate}</div>
        <div className="text-[10px]">{props.location}</div>
        {/* <div className={infoHover}>details</div> */}
        {hovered && (
          <div className={detailsContainer}>
            <div className="">Performers: {props.performer}</div>
            <div className="">Production: {props.production}</div>
          </div>
        )}
      </div>
    </div>
  );
}

const img = clsx(
  "rounded-l-md h-full w-full object-cover bg-[gray]/20 text-black/30 flex items-center justify-center"
);
const parent = clsx("mb-6 flex w-[300px] h-[80px] m-2 rounded-r-md relative ");
// const infoHover = clsx(
//   "font-bold bottom-0 absolute pb-2 text-[10px] italic text-black/30"
// );
const externalLink = clsx("text-center h-[16px] w-[16px] hover:scale-105");
const detailsContainer = clsx(
  "flex flex-col text-black/50 italic pt-[4px] text-[10px] absolute bg-[white] rounded-md shadow-lg p-2 z-10 -mr-4"
);
