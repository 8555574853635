import { useState } from "react";
import { Shows, Galleries, Workshops } from "./components";
import { Tabs } from '../../components'

const tabs = [
  { id: "performances", display: "performances" },
  { id: "galleries", display: "galleries" },
  { id: "workshops", display: "workshops" },
];
export default function Events() {
  const performances = tabs[0];
  const galleries = tabs[1];
  const workshops = tabs[2];

  const [selectedTab, setTab] = useState(performances);

  const tabsProps = {
    tabs,
    selectedTab: selectedTab.id,
    setTab,
    id: "events",
  };

  return (
    <>
      <Tabs {...tabsProps} />
      {selectedTab.id === performances.id && <Shows />}
      {selectedTab.id === galleries.id && <Galleries />}
      {selectedTab.id === workshops.id && <Workshops />}
    </>
  );
}
