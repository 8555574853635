
export const splitEventsByDate = (events) => {
    const cutoffTimestamp = Date.parse('2021-01-01');
  
    return events.reduce(
      (acc, event) => {
        const eventTimestamp = Date.parse(event.formatDate);
        if (eventTimestamp < cutoffTimestamp) {
          acc.preEvents.push(event);
        } else {
          acc.postEvents.push(event);
        }
        return acc;
      },
      { preEvents: [], postEvents: [] }
    );
  };
  
  export const getUpcoming = (date) => {
    const currentDate = new Date();
    let eventDate = new Date(date?.formatDate);
  
    return eventDate > currentDate;
  }