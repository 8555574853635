import clsx from "clsx";
import { useState } from "react";
import { routes } from "../../routes";
import logo from "../../assets/logo.png";

export default function MobileNav() {
  const [dropdownOpen, setDropdown] = useState(false);
  const location = window.location.pathname;
  const homepage = location === "/";
  return (
    <>
      <div onClick={() => setDropdown(!dropdownOpen)} className={dropdownBtn}>
        <img
          src={logo}
          alt={logoAlt}
          className={"h-auto " + (homepage ? "w-[300px] invert absolute top-0" : "py-4 w-[180px]")}
        />
      </div>
      <div className={''}>
        {dropdownOpen ? (
          <div className={dropdownContainer}>
            <div className={closeBtn} onClick={() => setDropdown(false)}>
              [ferme]
            </div>
            <a className={"mt-12 mb-10"} href={"/"}>
              <img
                src={logo}
                alt={logoAlt}
                className={"w-[180px] h-auto invert"}
              />
            </a>
            {routes.map(({ link, title }) => {
              const selected = location === link;
              return (
                <a
                  key={title}
                  className={"mb-12 " + (selected && selectedStyle)}
                  href={link}
                >
                  {title}
                </a>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

const logoAlt = "handwriting that says Violet Danse";

const closeBtn = clsx("w-full flex justify-end pr-4 pt-10 text-white/60");
const dropdownBtn = clsx(
  "flex items-center justify-center w-full cursor-pointer lg:hidden"
);
const dropdownContainer = clsx(
  "absolute z-20 h-screen bg-[#010100]/90 flex flex-col items-center text-lg w-full text-white top-0 left-0 text-black z-10"
);
const selectedStyle = clsx(" italic w-1/4 rounded-sm bg-white/40 text-center ");
