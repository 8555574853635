import clsx from "clsx";
import { routes } from "../../routes";
import logo from "../../assets/logo.png";

export default function DesktopNav() {
  return (
    <div className={parentContainer}>
      <a href="/">
        <img
          src={logo}
          alt="handwriting that says Violet Danse"
          className="w-[200px] h-auto mb-10"
        />
      </a>
      {routes.map(({ link, title }) => {
        const location = window.location.pathname;
        const selected = location === link
        return (
          <a
            key={title}
            className={"mb-8 flex flex-col" + (selected && " font-bold text-black")}
            href={link}
          >
            {title}
            {selected && <div className="w-20 border-b-2 border-black pt-2 -mb-2"/>}
          </a>
        );
      })}
    </div>
  );
}

const parentContainer = clsx(
  "hidden lg:flex flex-col w-[300px] justify-center text-black/50 tracking-wider lowercase"
);
