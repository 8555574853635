import clsx from "clsx";
import mtg from "../assets/about.jpg";

export default function Main() {
  return (
    <div className={parentContainer}>
      <div className="h-screen md:h-[calc(100vh-180px)]">
        <img src={mtg} className={img} alt={altText} />
      </div>
      <div className={caption}>
        {/* Mind The Gap XXIII, Giving While Needing, 2022, Photo by Lynn Lane */}
        Houston Botanic Garden, 2024, Photo by Hilary Schuhmacher
        {/* Mind The Gap XXXII, WATCHED: FOR YOUR VIEWING PLEASURE, 2024, Photo by Lynn Lane */}
      </div>
    </div>
  );
}

const altText =
  "violet sitting on a bench with two bags to the left of them and a camera to their right ";
const parentContainer = clsx("lg:h-screen flex flex-col justify-center md:mt-auto");
const img = clsx(
  "mb-[2px] self-center object-cover object-center h-full w-full"
);
const caption = clsx(
  "text-center lg:text-right text-[10px] lg:text-black/40 text-white/40 bg-[#010100] lg:bg-white -mt-[1px] pb-5"
);
