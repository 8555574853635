export default function Merci() {
  return (
    <div className="flex w-full flex-col h-screen items-center lg:mt-20">
      <div className="max-w-[600px] text-center px-4">
        <div className="lg:text-lg font-bold">
          This is a nonexhaustive list (in no order) of every entity that has at
          one point enabled and/or empowered me to create. Everything I do, is
          because I have been the recipient of kindness, generousity, and cosmic
          forehead kisses.
        </div>
        <div className="my-10">
          {list.map((entity) => (
            <div>{entity}</div>
          ))}
        </div>
      </div>
    </div>
  );
}

const list = [
  "Myself (all forms)",
  "Vega",
  "Griffin Perkins",
  "Stephanie Bobak",
  "Rachel Green",
  "The Pacific Ocean",
  "Jane Greenberg",
  "The tree in front of my house",
  "Lauren Wood",
  "Yuri On Ice",
  "Lana Del Ray's, Norman Fucking Rockwell",
  "Graham & Katherine",
  "Italia Alvarez",
  "The women from adult ballet",
  "The strangers who compliment/ed me",
  "Houston Academy of Dance's, Ginger Herrera",
  "Dorrell Martin",
  "MET Dance",
  "Tati Vice",
  "Tessa Salomone",
  "Sarah Alaways",
  "Alisa Mittin",
  "Ani DiFranco",
  "Therapists (good and bad)",
  "Everyone in my graduate cohort",
  "Meggan Watterson's, Mary Magdalene Revealed",
  "Jadd Tank",
  "Stacy Jones",
  "People who fight for what they believe in",
  "People who fight for kindness",
  "Le Sacre Coeur",
  "People I've loved",
  "People I've lost",
  "The listeners I've met",
  "Natifah White",
  "Persi Mey",
  "Jasmine Hearn's dancing",
  "Jen, who lent me their bra backstage",
  "The man who sharpened my eyeliner with scissors",
  "Everyone who has offered alternative support",
  "The sun as it warms my skin",
  "Swan soloist at Dance Salad 2014",
  "Stars in the city",
  "Rain when I needed it (and when I didn't)"
];
