import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
import { Main, About, Anthology, Events, Contact, Merci } from "./pages";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/events",
    element: <Events />,
  },
  {
    path: "/records",
    element: <Anthology />,
  },
  {
    path: "/thanks",
    element: <Merci />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
]);

export const routes = [
  { title: "About", link: "/about" },
  { title: "Records", link: "/records" },
  { title: "Events", link: "/events" },
  { title: "Merci", link: "/thanks" },
  { title: "Contact", link: "/contact" },
];
