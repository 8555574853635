import { motion } from "framer-motion";
import clsx from "clsx";

export default function Tabs(props) {
    return (
        <div className={parent}>
            <div className={tabContainer}>
                {props.tabs?.map((tab) => {
                    const selected = props.selectedTab === tab.id
                    const selectedTabStyle = selected ? "" : "text-black/50"
                    return (
                        <div
                            key={tab.id}
                            className={selectedTabStyle + tabStyle}
                            onClick={() => props.setTab(tab)}
                        >
                            {tab.display}
                            {selected && (
                                <motion.div
                                    className={underline}
                                    layoutId={props.id}
                                    initial={false}
                                    transition={{
                                        type: "spring",
                                        stiffness: 300,
                                        damping: 50,
                                    }}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
            <div className={border} />
        </div>
    );
}

const parent = clsx("flex md:w-[90%] flex-col mt-10");
const border = clsx("-mt-[1px] h-[1px] w-full bg-[gray]/20");
const underline = clsx("mt-2 h-[2px] rounded-full w-full bg-[black]");
const tabContainer = clsx(
    "flex w-full z-10 flex w-full justify-around text-lg",
);
const tabStyle = clsx(" cursor-pointer text-lg font-bold tracking-wider")