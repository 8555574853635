import guardians from "../../../../assets/galleries/guardians.png";
import me from "../../../../assets/galleries/me.png";

export const galleries = [
    {
      img: guardians,
      event: "Guardians",
      eventLink: "",
      displayDate: "August 6-September 4, 2023",
      formatDate: "September 4, 2023",
      performer: "Violet Moon",
      style: "Abstract Portrait Paintings",
      location: "Verbena Coffee House",
      production: "Verbena",
    },
    {
      img: me,
      event: "To Be Me",
      eventLink: "",
      displayDate: "August 3-September 2, 2022",
      formatDate: "September 2, 2022",
      performer: "Violet Moon",
      style: "Abstract Paintings",
      location: "Verbena Coffee House",
      production: "Verbena",
    },
  ]