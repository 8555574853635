
import bravescared from "../../../../assets/workshops/bravescared.jpg";
import consent from "../../../../assets/workshops/consent.jpg";
import soulcraft from "../../../../assets/workshops/soulcraft.png";
import emotionflow from "../../../../assets/workshops/emotionflow.png";

export const workshops = [
  {
    img: bravescared,
    event:
      "To Be Scared is To Be Brave: Connecting to the Body Through Change ",
    eventLink: "",
    displayDate: "October 19, 2024",
    formatDate: "October 19, 2024",
    performer: "Violet Moon",
    style: "",
    location: "Stude Park",
    production: "Houston Dance Co-Op",
  },
  {
    img: consent,
    event:
      "Consent Culture in Dance: Navigating Body Safety with Self & Others",
    eventLink: "https://www.canva.com/design/DAGTIbew0tI/FNOfoi1N_fVe3ecfV09qzQ/view",
    displayDate: "October 16, 2024",
    formatDate: "October 16, 2024",
    performer: "Violet Moon",
    style: "Abstract Portrait Paintings",
    location: "Houston MET Dance",
    production: "Dance Source Houston",
  },
  {
    img: emotionflow,
    event:
      "EmotionFlow: Somatic Movement and Feelings Reflection Group Therapy",
    eventLink: "",
    displayDate: "July 16-August 13, 2022",
    formatDate: "August 20, 2024",
    performer: "Violet Moon",
    style: "Somatic Movement",
    location: "Virtual",
    production: "CHill Counseling",
  },
  {
    img: soulcraft,
    event: "SoulCraft: Expressive Arts Group Therapy",
    eventLink: "",
    displayDate: "April 4-June 13, 2024",
    formatDate: "September 2, 2022",
    performer: "Violet Moon",
    style: "Expressive Arts",
    location: "Virtual",
    production: "CHill Counseling",
  },
];
