import ccfestival from "../../../../assets/shows/cc-fest.jpeg";
import choreo6 from "../../../../assets/shows/gen6.png";
import stage6 from "../../../../assets/shows/stage6.jpg";
import gen from "../../../../assets/shows/gen-dance.png";
import x from "../../../../assets/shows/x.jpg";
import freedom from "../../../../assets/shows/shout-freedom.jpeg";
import light from "../../../../assets/shows/shout-light.jpeg";
import barn1 from "../../../../assets/shows/barn1.jpeg";
import risk from "../../../../assets/shows/risk.jpg";
import bower from "../../../../assets/shows/bower.jpg";
import giving from "../../../../assets/shows/giving.jpg";
import thread from "../../../../assets/shows/thread.jpg";
import gallery from "../../../../assets/shows/dancegallery.jpg";
import five from "../../../../assets/shows/five.jpg";
import unbound from "../../../../assets/shows/unbound.jpg";
import mtg32 from "../../../../assets/shows/mtg32.jpg";

export const performances = [
  {
    event: "Barnstorm IX, Program A",
    eventLink: "",
    displayDate: "April 29-May 3, 2025",
    formatDate: "April 29, 2025",
    choreographer: "Violet Moon",
    performer: "Violet Moon",
    style: "Dance Theatre",
    location: "MATCH, Matchbox 2",
    production: "Dance Source Houston",
  },
  {
    event: "SoloDuo Dance Festival",
    eventLink: "",
    displayDate: "February 13-14, 2025",
    formatDate: "February 13, 2025",
    choreographer: "Violet Moon",
    performer: "Violet Moon",
    style: "Contemporary Dance",
    location: "Dixon Place",
    production: "WHITE WAVE",
  },
  {
    img: mtg32,
    event: "Mind The Gap XXXII",
    eventLink: "https://matchouston.org/events/2024/mind-gap-xxxii",
    displayDate: "December 3, 2024",
    formatDate: "December 3, 2024",
    choreographer: "Violet Moon",
    performer: "Violet Moon",
    style: "Dance Performance Art",
    location: "MATCH, Matchbox 2",
    production: "Dance Source Houston",
  },
  {
    img: unbound,
    event: "Limitless Dance Festival",
    eventLink:
      "https://www.eventbrite.com/e/limitless-dance-festival-tickets-1014811748257?aff=oddtdtcreator",
    displayDate: "November 1-3, 2024",
    formatDate: "November 1, 2024",
    performer: "Violet Moon, Guest Performers",
    style: "Contemporary Dance",
    location: "The DeLuxe Theater",
    production: "Rivkah French Choreography",
  },
  {
    img: five,
    event: "Mind The Gap XXXI",
    eventLink: "https://matchouston.org/events/2024/mind-gap-xxxi",
    displayDate: "October 8, 2024",
    formatDate: "October 8, 2024",
    performer: "Tessa Salomone, Violet Moon",
    style: "Dance Theatre",
    location: "MATCH, Matchbox 2",
    production: "Dance Source Houston",
  },
  {
    img: "",
    event: "Mise En Scene",
    eventLink: "https://www.framedance.org/mise-en-scene-3/",
    displayDate: "August 17, 2024",
    formatDate: "August 17, 2024",
    performer: "Violet Moon, Guest Performers",
    style: "Dance Theatre",
    location: "Frame Dance",
    production: "Frame Dance Productions",
  },
  {
    img: thread,
    event: "Barnstorm VIII, Program C",
    eventLink: "https://matchouston.org/events/2024/barnstorm-dance-fest-2024",
    displayDate: "May 28-June 1, 2024",
    formatDate: "June 1, 2024",
    performer: "Violet Moon, Alisa Mittin",
    style: "Contemporary Dance",
    location: "MATCH",
    production: "Dance Source Houston",
  },
  {
    img: risk,
    event: "Mind The Gap XXV",
    eventLink: "https://matchouston.org/events/2023/mind-gap-xxv",
    displayDate: "August 1, 2023",
    formatDate: "August 1, 2023",
    performer: "Violet Moon",
    style: "Performance Art",
    location: "MATCH",
    production: "Dance Source Houston",
  },
  {
    img: bower,
    event: "A Gift From The Bower",
    eventLink:
      "https://diverseworks.org/in-the-works/exhibtion-performance/a-gift-from-the-bower/",
    displayDate: "April 22, 2023",
    formatDate: "April 22, 2023",
    performer: "Transitory Sound And Movement Collective",
    style: "Performance Art",
    location:
      "Locke Surls Center for Art and Nature, Bower 5, Splendora, Texas",
    production: "DiverseWorks & Locke Surls Center for Art and Nature",
  },
  {
    img: giving,
    event: "Mind The Gap XXIII",
    eventLink: "https://matchouston.org/events/2023/mind-gap",
    displayDate: "February 28, 2023",
    formatDate: "February 28, 2023",
    performer: "Violet Moon",
    style: "Contemporary Dance",
    location: "MATCH",
    production: "Dance Source Houston",
  },
  {
    img: choreo6,
    event: "Choreographers X6",
    eventLink:
      "https://www.chron.com/neighborhood/memorial/events/article/JCC-presents-Houston-Choreographers-X6-dance-10884718.php",
    displayDate: "February 12, 2017",
    formatDate: "February 12, 2017",
    performer: "LÉON Contemporary Dance Company",
    style: "Contemporary Dance",
    location: "Kaplan Theatre",
    production: "Evelyn Rubenstein Jewish Community Center of Houston",
  },
  {
    img: gen,
    event: "Generation Dance Festival",
    eventLink:
      "https://matchouston.org/events/2016/generation-dance-festival-houston",
    displayDate: "May 14-15, 2016",
    formatDate: "May 15, 2016",
    performer: "LÉON Contemporary Dance Company",
    style: "Contemporary Dance",
    location: "MATCH",
    production: "LÉON Dance Arts",
  },
  {
    img: stage6,
    event: "Stage 6 Dance",
    eventLink: "https://matchouston.org/events/2015/stage-6-dance",
    displayDate: "December 13, 2015",
    formatDate: "December 13, 2015",
    performer: "LÉON Contemporary Dance Company",
    style: "Contemporary Dance",
    location: "MATCH",
    production: "LÉON Contemporary Dance Company",
  },
  {
    img: barn1,
    event: "Barnstorm I, Program 1",
    eventLink:
      "https://thedancedish.org/dance-source-houston-announces-lineup-for-the-barnstorm-dance-fest/",

    displayDate: "May 28-30, 2015",
    formatDate: "May 30, 2015",
    performer: "LÉON Contemporary Dance Company",
    style: "Contemporary Dance",
    location: "Barnevelder Movement/Arts Complex",
    production: "Dance Source Houston",
  },
  {
    img: ccfestival,
    event: "CounterCurrent Festival",
    eventLink:
      "https://www.houstonpublicmedia.org/articles/arts-culture/2015/04/15/59387/countercurrent-features-a-unique-tribute-to-powerful-women-2/",
    displayDate: "April 15, 2015",
    formatDate: "April 15, 2015",
    performer:
      "Celestina Billington, Bonnie Christiansen, Anastasiya Kopteva, Michiko McMahon, Violet Moon",
    style: "Dance Theatre",
    location: "Wilhelmina Grove",
    production:
      "Cynthia Woods Mitchell Center for the Arts, Sara Pauley, Omar Abi Azar, and Maya Zbib from Zoukak Theater in Lebanon",
  },
  {
    img: light,
    event: "The Shout: Where is The Light?",
    eventLink: "https://www.facebook.com/events/1623004541260881/?ref=newsfeed",
    displayDate: "January 29, 2015",
    formatDate: "January 29, 2015",
    performer: "Tam Fitzgerald, Violet Moon",
    style: "Improvisation",
    location: "Barnevelder Movement/Arts Complex",
    production: "The Shout Houston TX",
  },
  {
    img: x,
    event: "X",
    eventLink:
      "https://twitter.com/DanceSource/status/532926263175180289?ref_src=twsrc%5Etfw",
    displayDate: "Nov 14-15, 2014",
    formatDate: "Nov 15, 2014",
    performer: "Revolve Dance Company",
    style: "Contemporary Dance",
    location: "Barnevelder Movement/Arts Complex",
    production: "Revolve Dance Company",
  },
  {
    img: freedom,
    event: "The Shout: What is Freedom?",
    eventLink:
      "https://www.eventbrite.com/e/the-shout-tickets-13553411619?aff=zvents",
    displayDate: "October 23, 2014",
    formatDate: "October 23, 2014",
    performer: "Brianna Hall, Violet Moon",
    style: "Improvisation",
    location: "Barnevelder Movement/Arts Complex",
    production: "The Shout Houston TX",
  },
  {
    img: gallery,
    event: "Dance Gallery Festival",
    eventLink:
      "https://danceinforma.us/blog/texass-dance-gallery-festival-enters-5th-year",
    links: [
      "https://www.dancegalleryfestival.com/exhibitions",
      "https://www.aaartsalliance.org/events/the-dance-gallery-festival-texas-experience",
      "https://thedancedish.org/dance-gallery-festival-offers-power-punch-a-welcome-dose-of-subtlety/",
      "https://www.instagram.com/p/uELhbCOFBz/?hl=en",
    ],
    displayDate: "October 17, 2014",
    formatDate: "October 17, 2014",
    performer: "Revolve Dance Company",
    style: "Contemporary Dance",
    location: "James and Nancy Gaertner Performing Arts Center & Dance Theater",
    production: "Sam Houston State University",
  },
];
