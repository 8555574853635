import { performances } from "./constants";
import { splitEventsByDate, getUpcoming } from "../../helpers";
import EventItem from "../components/EventItem";

export default function Shows() {
  const { preEvents, postEvents } = splitEventsByDate(performances);

  return (
    <div className="flex py-10">
      <div className="flex flex-wrap justify-center">
        {postEvents.map((eventItem) => {
          const upcoming = getUpcoming(eventItem);

          const eventItemProps = { ...eventItem, upcoming };
          return <EventItem {...eventItemProps} />;
        })}
        <div className="w-full flex items-center justify-center">
          <div className="w-full border-b-2 -mt-2"></div>
          <div className="w-full text-center mb-4 text-xs text-black/40 italic">
            Intermission From 2018-2021.
          </div>
          <div className="w-full border-b-2 -mt-2"></div>
        </div>
        {preEvents.map((eventItem) => {
          const upcoming = getUpcoming(eventItem);

          const eventItemProps = { ...eventItem, upcoming };
          return <EventItem {...eventItemProps} />;
        })}
      </div>
    </div>
  );
}
